import axios from "axios";

import { components as OpenAPI } from "@/autogen/openapi";
import OpenAPIValues from "@/autogen/openapi.json";
import Urls from "@/autogen/urls";

import { mungeParameters } from "../utils";

type Page<T> = {
  results: T[];
  count: number;
};

export const listEmails = async (
  baseParams: any
): Promise<Page<OpenAPI["schemas"]["Email"]>> => {
  const response = await axios.get<Page<OpenAPI["schemas"]["Email"]>>(
    Urls["api:create_email"](),
    {
      params: mungeParameters({
        ...baseParams,
        status:
          !baseParams.status || baseParams.status.length === 0
            ? OpenAPIValues.components.schemas.EmailStatus.enum
            : baseParams.status,
        excluded_fields: ["body"],
      }),
    }
  );
  return response.data;
};
