<template>
  <div
    v-if="visible"
    :class="`flex rounded-lg items-center p-2 my-4 notice--${variant}`"
  >
    <QuestionMarkCircleIcon v-if="variant === 'info'" class="size-5" />
    <XCircleIcon v-else-if="variant === 'error'" class="size-5" />
    <ExclamationTriangleIcon v-else-if="variant === 'warning'" class="size-5" />
    <CheckCircleIcon v-else-if="variant === 'success'" class="size-5" />
    <ArrowPathIcon
      v-else-if="variant === 'pending'"
      class="size-5 animate-spin"
    />

    <div class="min-w-0 flex-1 ml-2.5">
      <slot></slot>
    </div>

    <button v-if="hideable" class="mr-2.5 cursor-pointer" @click="hide">
      <XMarkIcon class="size--5 opacity-60" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

import ArrowPathIcon from "@/icons/heroicons/arrow-path-micro.svg";
import CheckCircleIcon from "@/icons/heroicons/check-circle-micro.svg";
import ExclamationTriangleIcon from "@/icons/heroicons/exclamation-triangle-micro.svg";
import QuestionMarkCircleIcon from "@/icons/heroicons/question-mark-circle-micro.svg";
import XCircleIcon from "@/icons/heroicons/x-circle-micro.svg";
import XMarkIcon from "@/icons/heroicons/x-mark-micro.svg";

export type Variant = "info" | "error" | "warning" | "success" | "pending";

defineProps<{
  variant: Variant;
  hideable?: boolean;
}>();

const visible = ref(true);
const hide = () => {
  visible.value = false;
};
</script>

<style lang="scss">
@import "@/styles/hues";

.notice--info {
  background: $info-light;
  color: $info-dark;

  a {
    color: $info-dark;
  }

  code {
    background-color: $info-dark !important;
    color: $info-light;
  }
}

.notice--error {
  background: $danger-light;
  color: $danger-dark;

  a {
    color: $danger-dark;
  }

  code {
    background-color: $danger-dark !important;
    color: $warning-light;
  }
}

.notice--warning {
  background: $warning-light;
  color: $warning-color;

  a {
    color: $warning-color;
  }

  code {
    background-color: $warning-color !important;
    color: $warning-light;
    border-radius: 5px;
  }
}

.notice--pending {
  background: $gray-1;
  color: $gray-4;

  code {
    background-color: $gray-4 !important;
    color: $gray-2;
    border-radius: 5px;
  }
}

.notice--success {
  background: $success-light;
  color: $success-dark;

  a {
    color: $success-dark;
  }

  code {
    background-color: $success-color !important;
    color: $success-light;
    border-radius: 5px;
  }
}
</style>
