<template>
  <div
    class="flex select-none flex-col gap-0.5 bg-gray-100 p-2"
    @keydown="onKeyDown"
    @keyup="onKeyUp"
  >
    <div
      v-for="fieldId in filter.checkboxes"
      :key="fieldId"
      class="flex items-center gap-2"
    >
      <input
        :id="id + fieldId"
        type="checkbox"
        :checked="selected?.includes(fieldId)"
        class="form-checkbox rounded border-gray-300 bg-gray-200 text-blue-500 transition-colors focus:ring-blue-200"
        @input="onChange(fieldId, $event)"
      />

      <label
        :for="id + fieldId"
        :aria-description="getDescription(fieldId)"
        class="mb-0 grow text-sm"
      >
        <component
          :is="filter.component || DefaultParameterLabel"
          :id="fieldId"
        />
      </label>

      <span
        v-if="flatAggs[fieldId] !== undefined"
        aria-hidden="true"
        class="tabular-nums text-sm text-gray-400"
      >
        {{ "" + flatAggs[fieldId] }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, useId } from "vue";

import { Aggregate } from "@/types/aggregate";

import DefaultParameterLabel from "../DefaultParameterLabel.vue";
import { CheckboxParameterSet } from "../lib";
import { CheckboxSnapshot } from "./types";

const selected = defineModel<string[]>();
const snapshot = defineModel<CheckboxSnapshot | null>("snapshot");

const id = useId();

const props = defineProps<{
  filter: CheckboxParameterSet;
  aggregates: Aggregate<string>[] | undefined;
}>();

const flatAggs = computed(() => {
  const aggs = props.aggregates;
  if (!aggs) {
    return {};
  }

  return Object.fromEntries(aggs.map((agg) => [agg.parameter, agg.count]));
});

let shiftHeld = false;
const onKeyDown = (event: KeyboardEvent) => {
  if (event.shiftKey) {
    shiftHeld = true;
  }
};
const onKeyUp = (event: KeyboardEvent) => {
  if (!event.shiftKey) {
    shiftHeld = false;
  }
};

const onChange = (fieldId: string, event: Event) => {
  const lastSnapshot = snapshot.value;
  const checked = (event.target as HTMLInputElement).checked;

  const nextSnapshot: CheckboxSnapshot = {
    filterId: props.filter.id,
    fieldId: fieldId,
    checked: checked,
  };

  if (
    shiftHeld &&
    lastSnapshot &&
    lastSnapshot.filterId === nextSnapshot.filterId &&
    lastSnapshot.checked === nextSnapshot.checked
  ) {
    event.preventDefault();

    setCheckboxesBetween({
      between: [lastSnapshot.fieldId, fieldId],
      checked: checked,
    });
  } else {
    setCheckbox(nextSnapshot);
  }

  snapshot.value = nextSnapshot;
};

const setCheckbox = ({ fieldId, checked }: CheckboxSnapshot) => {
  const prev = selected.value;

  if (checked) {
    selected.value = prev ? prev.concat(fieldId) : [fieldId];
  } else if (prev) {
    const next = prev.filter((x) => x !== fieldId);

    selected.value = next;
  }
};

const setCheckboxesBetween = ({
  between,
  checked,
}: {
  between: [string, string];
  checked: boolean;
}) => {
  const options = props.filter.checkboxes;

  const aIndex = options.indexOf(between[0]);
  const bIndex = options.indexOf(between[1]);

  if (aIndex === -1 || bIndex === -1) {
    return;
  }

  const from = Math.min(aIndex, bIndex);
  const to = Math.max(aIndex, bIndex);
  const selection = options.filter((_value, idx) => idx >= from && idx <= to);

  if (checked) {
    const next = selected.value?.slice() ?? [];

    for (const value of selection) {
      if (next.includes(value)) {
        continue;
      }

      next.push(value);
    }

    selected.value = next;
  } else {
    const next = selected.value?.filter((value) => {
      return !selection.includes(value);
    });

    selected.value = next;
  }
};

const getDescription = (fieldId: string) => {
  const count = flatAggs.value[fieldId];
  if (count !== undefined) {
    return `${count} items`;
  }
};
</script>
