import { computed, ref } from "vue";

import { useStore as useScaffoldingStore } from "@/store/stores/scaffolding";
import { Newsletter } from "@/types/newsletter";

export const useNewsletter = () => {
  const scaffoldingStore = useScaffoldingStore();

  const backingNewsletter = computed<Newsletter>(
    () => scaffoldingStore.newsletter as Newsletter
  );
  const newsletter = ref<Newsletter>({
    ...backingNewsletter.value,
  });

  async function save() {
    await scaffoldingStore.updateNewsletter(newsletter.value);
  }
  return {
    newsletter,
    save,
  };
};
