import { ref, shallowRef } from "vue";

const useQuery = <T>(
  fn: () => Promise<T>,
  onSuccess?: () => void,
  onError?: (err: unknown) => void
) => {
  const data = shallowRef<T | null>(null);
  const loading = ref(false);
  const error = ref<any>(null);

  const execute = async () => {
    loading.value = true;
    try {
      const result = await fn();
      data.value = result;
      onSuccess?.();
    } catch (e) {
      error.value = e;
      onError?.(e);
    }
    loading.value = false;
  };

  return {
    data,
    loading,
    error,
    execute,
  };
};

export default useQuery;
