<template>
  <div
    class="text-gray-400 mt-0 mb-0 SettingDescription text-[14px] font-medium"
  >
    <slot></slot>
  </div>
</template>

<!-- Note: not scoped because `markdown` creates unscoped DOM elements. -->
<style lang="scss">
@import "@/styles/hues";

.SettingDescription p:first-child {
  margin-top: 0;
}

.SettingDescription p:last-child {
  margin-bottom: 0;
}
</style>
