<template>
  <SearchModal
    v-model="query"
    :data="results"
    :value="value"
    :pending="pending"
    @close="$emit('close')"
  />
</template>

<script lang="ts" setup>
import { computed, ref, watch } from "vue";

import { useStore } from "@/store/stores/search";
import { debounce } from "@/utils";

import SearchModal from "./Component.vue";

const searchStore = useStore();

const query = ref("");

const value = computed(() => searchStore.value);
const pending = computed(() => searchStore.pending);
const results = computed(() => searchStore.results);

watch(
  query,
  debounce((value) => {
    searchStore.value = value;
  }, 500),
  {
    immediate: true,
  }
);

defineEmits(["close"]);
</script>
