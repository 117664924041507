import { type Component } from "vue";

import { RouteLocation } from "@/router/types";
import { INTEGRATION_ID_TO_CONFIGURATION } from "@/screens/Settings/integrations";
import { Permission } from "@/types/account";
import { Newsletter } from "@/types/newsletter";
import { SearchResult } from "@/types/search_result";

import { SEARCH_CONTEXT } from "./routes";

type UnprocessedResult = {
  title: string;
  route: RouteLocation;
  brandIcon?: Component;
};

export default function (
  searchString: string,
  permissions: Permission["permissions"],
  newsletter: Newsletter
): SearchResult[] {
  if (searchString === "" || searchString === null) {
    return [];
  }

  const basicPages: UnprocessedResult[] = Object.entries(SEARCH_CONTEXT)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, config]) => {
      return (
        config.title.toLowerCase().includes(searchString.toLowerCase()) ||
        (config.matches &&
          config.matches.some((match) =>
            match.toLowerCase().includes(searchString.toLowerCase())
          ))
      );
    })
    .filter(([, config]) =>
      config.hide ? !config.hide(permissions, newsletter) : true
    )
    .map(([route, config]) => {
      return {
        title: config.title,
        route: {
          name: route,
        },
      };
    }) as UnprocessedResult[];

  const integrations: UnprocessedResult[] = Object.entries(
    INTEGRATION_ID_TO_CONFIGURATION
  )
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, config]) =>
      config.name.toLowerCase().includes(searchString.toLowerCase())
    )
    .map(([id, config]) => {
      return {
        title: `Configure ${config.name} integration`,
        brandIcon: config.brandIcon,
        route: {
          name: "/settings/integrations/[id]",
          params: { id },
        },
      };
    });

  const results = [...basicPages, ...integrations];

  // Dedupe internal pages so we don't show the same page twice.
  return results.map((result) => {
    return {
      type: "page",
      text: result.title,
      priority: 1,
      subtext: null,
      subtexts: [],
      obj: {
        ...(Object.assign({}, result.route) as any),
        id: result.title,
      },
      brandIcon: result.brandIcon,
    };
  });
}
