import { Parameter } from "@/components/Layout/ParameterWidget/lib";
import { safeLocalStorageGetItem } from "@/store/local_storage";

const extractParameters = (
  querystring: string,
  keys: readonly string[]
): Parameter<string> => {
  let state: Parameter<string> = {};
  const params = new URLSearchParams(querystring);
  keys.map((key) => {
    const value = params.getAll(key + "[]");
    state[key] = value;
  });
  return state;
};

export const constructInitialParameters = (
  pathname: string,
  localStorageKey: string,
  parameterKeys: readonly string[]
): Parameter<string> => {
  if (window.location.pathname === pathname) {
    return extractParameters(window.location.search, parameterKeys);
  }
  const localParameters = safeLocalStorageGetItem(localStorageKey);
  if (localParameters) {
    return JSON.parse(localParameters);
  }
  return {};
};
