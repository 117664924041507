<template>
  <input
    :id="id"
    v-model="modelValue"
    type="checkbox"
    :indeterminate="indeterminate"
    :name="name"
    class="rounded border-gray-300 text-blue-500 transition-colors focus:ring-offset-0"
  />
</template>

<script lang="ts" setup>
defineProps<{
  indeterminate?: boolean;
  id?: string;
  name?: string;
}>();

const modelValue = defineModel<boolean>("modelValue");
</script>
