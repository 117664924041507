<template>
  <ToastRoot
    open
    :duration="Infinity"
    type="background"
    class="toast rounded-lg border border-gray-200 bg-white px-4 py-3 shadow-lg transition hover:border-gray-300 hover:shadow-xl"
  >
    <ToastTitle
      class="line-clamp-2 overflow-hidden break-words text-sm font-medium leading-6"
    >
      {{ text }}
    </ToastTitle>
  </ToastRoot>
</template>

<script setup lang="ts">
import { ToastRoot, ToastTitle } from "radix-vue";
import { computed, onUnmounted } from "vue";

import type { components as OpenAPI } from "@/autogen/openapi";
import { generateBulkActionConfiguration } from "@/components/BulkActionModal/lib";
import useInterval from "@/lib/interval";
import { useStore as useBulkActionsStore } from "@/store/stores/bulk_actions";

const store = useBulkActionsStore();

const props = defineProps<{
  bulkAction: OpenAPI["schemas"]["BulkAction"] & {
    onSuccess?: () => void;
  };
}>();

const text = computed(() => {
  const bulkAction = props.bulkAction;
  const config = generateBulkActionConfiguration({})[bulkAction.type];

  return `${config.label}${
    (bulkAction.metadata.ids === undefined
      ? []
      : (bulkAction.metadata.ids as string[])
    ).length > 1
      ? "s"
      : ""
  }...`;
});

useInterval(() => {
  store.retrieveBulkAction(props.bulkAction.id);
}, 1_000);

onUnmounted(() => {
  props.bulkAction.onSuccess?.();
});
</script>
