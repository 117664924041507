<template>
  <TransitionRoot as="template" :show="open" appear>
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      :open="open"
      @close="
        open = false;
        close();
      "
    >
      <div
        class="flex items-end justify-center min-h-[100dvh] md:px-4 text-center md:block"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            :class="{
              'fixed inset-0': true,
              'bg-gray-500 bg-opacity-75 transition-opacity': true,
            }"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden md:inline-block md:align-middle md:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          enter-to="opacity-100 translate-y-0 md:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 md:scale-100"
          leave-to="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
        >
          <div
            :class="[
              'inline-block align-bottom bg-white rounded-t-lg md:rounded-lg p-4 text-left overflow-visible transform transition-all md:my-8 md:align-middle w-full md:p-4 ' +
                containerClass,
              {
                'shadow-xl': true,
              },
            ]"
          >
            <div>
              <div class="space-y-4">
                <DialogTitle v-if="$slots.header" as="h3" class="font-bold">
                  <slot name="header"></slot>
                </DialogTitle>
                <!-- This wrapper div is load-bearing! It makes sure
                    that the content of `body` is self-contained, so that
                    a Setting placed directly inside of it does not think that
                    the header (above) is a sibling. -->
                <div>
                  <slot name="body"></slot>
                </div>
                <slot name="footer"></slot>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { computed, onMounted, onUnmounted, ref } from "vue";

import { useStore } from "@/store/stores/scaffolding";

type Variant = "wide" | "very-wide" | "super-wide" | "narrow";

const props = defineProps<{
  variant?: Variant;
}>();

const emit = defineEmits(["close", "save"]);

const scaffoldingStore = useStore();
const open = ref(true);

const containerClass = computed(() => {
  return {
    wide: "md:max-w-xl",
    narrow: "md:max-w-md",
    "very-wide": "min-w-[20rem] md:max-w-3xl",
    "super-wide": "min-w-[20rem] md:max-w-5xl",
  }[props.variant || "wide"];
});

onMounted(() => {
  scaffoldingStore.toggleDialog(true);
  document.addEventListener("keydown", handleKeyDown);
});

onUnmounted(() => {
  scaffoldingStore.toggleDialog(false);
  document.removeEventListener("keydown", handleKeyDown);
});

const close = () => {
  scaffoldingStore.toggleDialog(false);
  setTimeout(() => {
    emit("close");
  }, 300);
};

const handleKeyDown = (e: KeyboardEvent) => {
  // The escape key.
  if (e.keyCode === 27) {
    close();
  }
};
</script>
