<template>
  <div class="relative">
    <textarea
      :id="id"
      v-model="modelValue"
      rows="4"
      class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 !font-[Inter] !mb-0"
      :placeholder="placeholder || 'Write your thoughts here...'"
      :readonly="readonly"
      :autofocus="autofocus"
      :required="required"
      :name="name"
      :disabled="disabled"
    />
    <slot />
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  readonly?: boolean;
  id?: string;
  autofocus?: boolean;
  required?: boolean;
  placeholder?: string;
  name?: string;
  disabled?: boolean;
}>();

const modelValue = defineModel<string>("modelValue");
</script>
