<!-- Simpler component API that gets the job done -->

<template>
  <Root>
    <Trigger>
      <TriggerButton :variant="props.variant">{{ props.label }}</TriggerButton>
    </Trigger>

    <Container :scrollable="props.scrollable">
      <slot></slot>
    </Container>
  </Root>
</template>

<script setup lang="ts">
import Container from "./Container.vue";
import Root from "./Root.vue";
import Trigger from "./Trigger.vue";
import TriggerButton from "./TriggerButton.vue";

const props = defineProps<{
  label: string;
  variant?: "default" | "secondary";
  scrollable?: boolean;
}>();
</script>
