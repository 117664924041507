<template>
  <label :for="props.id" class="text-gray-500 m-0">{{ props.name }}</label>
</template>

<script setup lang="ts">
const props = defineProps<{
  id: string;
  name: string;
}>();
</script>
