<template>
  <DropdownMenuTrigger v-if="!context.isMobile" as-child>
    <slot></slot>
  </DropdownMenuTrigger>
  <DrawerTrigger v-else as-child>
    <slot></slot>
  </DrawerTrigger>
</template>

<script setup lang="ts">
import { DropdownMenuTrigger } from "radix-vue";
import { DrawerTrigger } from "vaul-vue";

import { inject } from "./context";

const context = inject();
</script>
