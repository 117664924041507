<template>
  <div
    class="@container border-b border-b-gray-200 py-4 first:pt-0 last:border-b-0 last:pb-0"
  >
    <div
      :class="{
        'block @xl:flex w-full space-y-2 @xl:space-y-0 @xl:space-x-4 justify-between': true,
        // If it has a toggle, center-align it vertically.
        'has-[button[role=switch]]:items-center': true,
        // ...or a Switcher...
        'has-[[type=radio][role=switch]]:items-center': true,
        // Or if it has a color input.
        'has-[input[type=color]]:items-center': true,
        'items-center': !$slots.description,
        // If it's got an icon, we always flex and just hide the description.
        '!flex !space-x-4 !space-y-0 items-center': $slots.icon,
      }"
    >
      <div v-if="$slots.icon">
        <slot name="icon"></slot>
      </div>
      <div class="@xl:w-1/2 flex-1">
        <div
          v-if="$slots.header || $slots.validator"
          class="flex justify-between"
        >
          <SettingHeader v-if="$slots.header">
            <slot name="header"></slot>
          </SettingHeader>
          <SettingValidator v-if="$slots.validator">
            <slot name="validator"></slot>
          </SettingValidator>
        </div>
        <div
          v-if="$slots.description"
          :class="{
            'hidden @xl:block': $slots.icon,
          }"
        >
          <SettingDescription>
            <slot name="description"></slot>
          </SettingDescription>
        </div>
      </div>
      <div
        :class="{
          // All of these rules boil down to: does this setting have a button or other element that should be aligned to the right?
          'flex-1 @xl:text-right has-[a.w-full]:flex-initial': true,
          'has-[button[role=switch]]:flex-initial': true,
          '[&:has(*>button:only-child)]:flex-initial': true,
          // For filters.
          'has-[button.w-8]:!flex-1': true,
        }"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import SettingDescription from "./SettingDescription.vue";
import SettingHeader from "./SettingHeader.vue";
import SettingValidator from "./SettingValidator.vue";

export default defineComponent({
  components: { SettingHeader, SettingValidator, SettingDescription },
  provide() {
    return {
      id: this.id,
    };
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
});
</script>
