import { onScopeDispose, Ref, ref } from "vue";

export const useMediaQuery = (condition: string): Readonly<Ref<boolean>> => {
  const query = matchMedia(condition);
  const match = ref(query.matches);

  const listener = () => {
    match.value = query.matches;
  };

  query.addEventListener("change", listener);
  onScopeDispose(() => query.removeEventListener("change", listener));

  return match;
};
