import Content from "./Content.vue";
import Hint from "./Hint.vue";
import PremiumOnly from "./PremiumOnly.vue";
import Root from "./Root.vue";
import Trigger from "./Trigger.vue";

export default {
  Content,
  Hint,
  PremiumOnly,
  Root,
  Trigger,
};
