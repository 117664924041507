<template>
  <div v-if="props.disabled" class="flex flex-col">
    <div
      v-for="(item, idx) in items"
      :key="item.id"
      class="self-stretch flex flex-col"
    >
      <slot :item="item" :index="idx" />
    </div>
  </div>
  <CoreDraggable v-else v-model="items" item-key="id" class="flex flex-col">
    <!-- First child has to be a DOM element that draggable.js can take control -->
    <template #item="{ element, index }">
      <div class="self-stretch flex flex-col">
        <slot :item="element" :index="index" />
      </div>
    </template>
  </CoreDraggable>
</template>

<script setup lang="ts" generic="TItem extends { id: string | number }">
import CoreDraggable from "vuedraggable";

const items = defineModel<TItem[]>();

const props = defineProps<{
  disabled?: boolean;
}>();

defineSlots<{
  default(props: { item: TItem; index: number }): unknown;
}>();
</script>
