<template>
  <fieldset class="relative px-3 mb-1">
    <input
      :id="id"
      type="date"
      step="1"
      :name="id"
      placeholder=" "
      class="!bg-gray-50 pt-5 !mb-2 border-gray-300 rounded w-full"
      :value="$props.modelValue"
      autocomplete=""
      @change="handleChange"
    /><label
      :for="id"
      class="absolute top-4 left-6 text-xs"
      style="transform: translateY(-10px)"
      >{{ label }}</label
    >
  </fieldset>
</template>

<script lang="ts" setup>
defineProps<{
  id: string;
  label: string;
  modelValue?: string | undefined | null;
}>();

const emit = defineEmits(["update:modelValue"]);

const handleChange = (e: any) =>
  emit("update:modelValue", (<HTMLInputElement>e.target)?.value);
</script>
