import { ref } from "vue";

import { components as OpenAPI } from "@/autogen/openapi";
import api from "@/lib/api/base";
import { useStore } from "@/store/stores/notifications";
import { uuid } from "@/utils";

import { removeToastById, showToast } from "./toasts";

const TIMEOUT_BEFORE_SHOWING_POPUP_IN_MILLISECONDS = 300 * 1000;

const recursivelyPoll = (id: string, callback?: () => void) => {
  api
    .path("/exports/{id}")
    .method("get")
    .create()({ id })
    .then((ex) => {
      if (ex.data.status === "ready") {
        showToast({
          type: "success",
          title: `Export successful!`,
        });

        if (ex.data.url) {
          window.location.href = ex.data.url;
        }
        if (callback) {
          callback();
        }
      } else {
        setTimeout(() => {
          recursivelyPoll(id, callback);
        }, 1000);
      }
    });
};

type State =
  | {
      state: "not_started" | "confirming" | "pending";
    }
  | {
      state: "done";
      url: string;
    };

const useExport = (collections: OpenAPI["schemas"]["ExportCollection"][]) => {
  const state = ref<State>({ state: "not_started" });
  const notificationsStore = useStore();

  const confirm = () => {
    state.value = { state: "confirming" };
  };

  const process = async (parameters: { [key: string]: any }) => {
    const pendingToastId = showToast({
      type: "pending",
      title: `Exporting`,
    });
    state.value = { state: "pending" };

    api
      .path("/exports")
      .method("post")
      .create()({
        collections,
        parameters,
      })
      .then((ex) => {
        if (ex.data.status === "ready") {
          if (ex.data.url) {
            state.value = { state: "done", url: ex.data.url };
            removeToastById(pendingToastId);
            showToast({
              type: "success",
              title: `Export successful!`,
            });
          }
        } else {
          const timeout = setTimeout(() => {
            notificationsStore.notifications.push({
              id: uuid(),
              headline: "Your export is taking longer than expected.",
              description:
                "Sorry about that! The system is under load right now (it's an 'us' problem, not a 'you' problem.) We'll email you the export when it's ready; you can close this page.",
              route: null,
              creation_date: new Date().toISOString(),
            });
          }, TIMEOUT_BEFORE_SHOWING_POPUP_IN_MILLISECONDS);
          recursivelyPoll(ex.data.id, () => {
            if (ex.data.url) {
              state.value = { state: "done", url: ex.data.url };
              removeToastById(pendingToastId);
              showToast({
                type: "success",
                title: `Export successful!`,
              });
            }
            clearTimeout(timeout);
          });
        }
      });
  };

  return {
    process,
    confirm,
    state,
  };
};

export default useExport;
