import * as Sentry from "@sentry/vue";

const mungeUserAgent = (userAgent: string): string => {
  const browser = userAgent.match(
    /(chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
  );
  if (browser === null) {
    return "unknown";
  }
  const version = parseInt(browser[2], 10);
  const name = browser[1].toLowerCase();
  if (name === "trident") {
    return "ie" + version;
  }
  if (name === "chrome" && version > 27) {
    return "chrome";
  }
  if (name === "safari" && version > 5) {
    return "safari";
  }
  if (name === "firefox" && version > 21) {
    return "firefox";
  }
  return "unknown";
};

const increment = (
  metric: string,
  value: number,
  additionalTags?: Record<string, string>
) => {
  const tags = {
    browser: mungeUserAgent(navigator.userAgent),
    domain: window.location.hostname,
    ...(additionalTags || {}),
  };
  Sentry.metrics.increment(metric, value, { tags });
  console.debug("metric.increment", metric, value, tags);
};

export default { increment };
