import { type Component } from "vue";

import { Configuration } from "@/design_system/Form/Builder.vue";
import BlueskyIcon from "@/icons/brands/bluesky.svg";
import FathomIcon from "@/icons/brands/fathom.svg";
import GitHubIcon from "@/icons/brands/github.svg";
import GoogleIcon from "@/icons/brands/google-color.svg";
import LinkedInIcon from "@/icons/brands/linkedin.svg";
import MemberfulIcon from "@/icons/brands/memberful-color.svg";
import MetaIcon from "@/icons/brands/meta.svg";
import PlausibleIcon from "@/icons/brands/plausible.svg";
import RedditIcon from "@/icons/brands/reddit.svg";
import ShopifyIcon from "@/icons/brands/shopify.svg";
import SquarespaceIcon from "@/icons/brands/squarespace.svg";
import StripeIcon from "@/icons/brands/stripe.svg";
import TwitterIcon from "@/icons/brands/twitter.svg";
import UmamiIcon from "@/icons/brands/umami.svg";
import WebflowIcon from "@/icons/brands/webflow.svg";
import ZapierIcon from "@/icons/brands/zapier.svg";
import { Newsletter } from "@/types/newsletter";

export type Integration<K extends keyof Newsletter> = {
  name: string;
  description: string;
  buttonText: string;
  brandIcon: Component;
} & (
  | {
      type: "configuration";
      configuration: Configuration<{
        [key in K]: Newsletter[key];
      }>;
    }
  | {
      type: "link";
      url: string;
    }
);

const BLUESKY_INTEGRATION: Integration<"bluesky_username" | "bluesky_api_key"> =
  {
    type: "configuration",
    name: "Bluesky",
    brandIcon: BlueskyIcon,
    description: "Crosspost your articles to your Bluesky account.",
    buttonText: "Configure",
    configuration: {
      bluesky_username: {
        header: "Bluesky username",
        description: "Your Bluesky username.",
        type: "text",
      },
      bluesky_api_key: {
        header: "Bluesky app-specific password",
        description: "Your Bluesky app-specific password.",
        type: "text",
      },
    },
  };

const FATHOM_INTEGRATION: Integration<"fathom_code"> = {
  type: "configuration",
  name: "Fathom",
  brandIcon: FathomIcon,
  description: "Track page views using your Fathom code.",
  buttonText: "Configure",
  configuration: {
    fathom_code: {
      header: "Fathom code",
      description:
        "If you have a Fathom account, you can paste your site ID here to automatically add the Fathom script to your site.",
      type: "text",
    },
  },
};

const MEMBERFUL_INTEGRATION: Integration<
  "memberful_api_key" | "memberful_username"
> = {
  type: "configuration",
  name: "Memberful",
  brandIcon: MemberfulIcon,
  description: "Sync your Buttondown subscribers with Memberful.",
  buttonText: "Configure",
  configuration: {
    memberful_api_key: {
      header: "Memberful API key",
      description: "Your Memberful API key.",
      type: "text",
    },
    memberful_username: {
      header: "Memberful username",
      description: "Your Memberful username.",
      type: "text",
    },
  },
};

const PLAUSIBLE_INTEGRATION: Integration<"is_using_plausible"> = {
  type: "configuration",
  name: "Plausible",
  brandIcon: PlausibleIcon,
  description: "Track page views using your Plausible code.",
  buttonText: "Configure",
  configuration: {
    is_using_plausible: {
      header: "Use Plausible",
      type: "boolean",
    },
  },
};

const REDDIT_INTEGRATION: Integration<"reddit_code"> = {
  type: "configuration",
  name: "Reddit",
  brandIcon: RedditIcon,
  description: "Add a tracking pixel to measure conversions from Reddit Ads.",
  buttonText: "Configure",
  configuration: {
    reddit_code: {
      header: "Reddit pixel ID",
      description:
        "Your Reddit pixel ID. You can find this in your Reddit Ads dashboard.",
      type: "text",
    },
  },
};

const META_INTEGRATION: Integration<"meta_pixel_id"> = {
  type: "configuration",
  name: "Meta",
  brandIcon: MetaIcon,
  description: "Track page views using your Meta pixel ID.",
  buttonText: "Configure",
  configuration: {
    meta_pixel_id: {
      header: "Meta pixel ID",
      description:
        "Your Meta pixel ID. You can find this in your Meta dashboard.",
      type: "text",
    },
  },
};

const SHOPIFY_INTEGRATION: Integration<"shopify_api_key" | "shopify_url"> = {
  type: "configuration",
  name: "Shopify",
  brandIcon: ShopifyIcon,
  description: "Trigger events in Buttondown based on Shopify events.",
  buttonText: "Configure",
  configuration: {
    shopify_api_key: {
      header: "Shopify API key",
      description: "Your Shopify API key.",
      type: "text",
    },
    shopify_url: {
      header: "Shopify URL",
      description:
        "The URL of the Shopify store you want to invite subscribers to.",
      type: "text",
    },
  },
};

const UMAMI_INTEGRATION: Integration<"umami_website_id"> = {
  type: "configuration",
  name: "Umami",
  brandIcon: UmamiIcon,
  description: "Track page views using your Umami Website ID.",
  buttonText: "Configure",
  configuration: {
    umami_website_id: {
      header: "Umami Website ID",
      description:
        "Your Umami Website ID. You can find this in the Details panel of your website's Edit page on Umami.",
      type: "text",
    },
  },
};

const GOOGLE_INTEGRATION: Integration<"google_tag_manager_code"> = {
  type: "configuration",
  name: "Google",
  brandIcon: GoogleIcon,
  description: "Track page views using your GTM identifier.",
  buttonText: "Configure",
  configuration: {
    google_tag_manager_code: {
      header: "Google Tag Manager ID",
      description:
        "Your Google Tag Manager ID. You can find this in your Google Tag Manager dashboard.",
      type: "text",
    },
  },
};

const GITHUB_INTEGRATION: Integration<
  | "github_api_key"
  | "github_subscriber_metadata_key"
  | "github_organization_slug"
  | "github_team_slug"
> = {
  type: "configuration",
  name: "GitHub",
  brandIcon: GitHubIcon,
  description: "Automatically invite new subscribers to your organization.",
  buttonText: "Configure",
  configuration: {
    github_api_key: {
      header: "GitHub API key",
      description: "Your GitHub API key.",
      type: "text",
    },
    github_subscriber_metadata_key: {
      header: "GitHub subscriber metadata key",
      description:
        "The metadata key that contains the GitHub username for each subscriber.",
      type: "text",
    },
    github_organization_slug: {
      header: "GitHub organization slug",
      description:
        "The slug of the GitHub organization you want to invite subscribers to.",
      type: "text",
    },
    github_team_slug: {
      header: "GitHub team slug",
      description:
        "The slug of the GitHub team you want to invite subscribers to.",
      type: "text",
    },
  },
};

export const INTEGRATION_ID_TO_CONFIGURATION: Record<
  string,
  Integration<keyof Newsletter>
> = {
  fathom: FATHOM_INTEGRATION,
  memberful: MEMBERFUL_INTEGRATION,
  plausible: PLAUSIBLE_INTEGRATION,
  reddit: REDDIT_INTEGRATION,
  umami: UMAMI_INTEGRATION,
  google: GOOGLE_INTEGRATION,
  github: GITHUB_INTEGRATION,
  shopify: SHOPIFY_INTEGRATION,
  meta: META_INTEGRATION,
  bluesky: BLUESKY_INTEGRATION,
};

export type INTEGRATION_ID = keyof typeof INTEGRATION_ID_TO_CONFIGURATION;

export const INTEGRATIONS: Integration<any>[] = [
  {
    type: "link",
    name: "Webflow",
    brandIcon: WebflowIcon,
    description: "Create a custom landing page for your newsletter.",
    url: "https://buttondown.com/blog/2019-10-11-adding-a-buttondown-subscription-form-to-webflow",
    buttonText: "Learn more ↗",
  },
  {
    type: "link",
    name: "Stripe",
    brandIcon: StripeIcon,
    description: "Fire automations in Buttondown based on Stripe events.",
    buttonText: "Configure",
    url: "/settings/paid-subscriptions",
  },
  {
    type: "link",
    name: "Squarespace",
    brandIcon: SquarespaceIcon,
    description: "Add a subscription form to your Squarespace site.",
    url: "https://buttondown.com/blog/2019-10-24-adding-a-buttondown-subscription-form-to-squarespace",
    buttonText: "Learn more ↗",
  },
  {
    type: "link",
    name: "Zapier",
    brandIcon: ZapierIcon,
    description: "Connect Buttondown to hundreds of other apps.",
    url: "https://zapier.com/apps/buttondown/integrations",
    buttonText: "Learn more ↗",
  },
  {
    type: "link",
    name: "LinkedIn",
    brandIcon: LinkedInIcon,
    description: "Crosspost your newsletters to LinkedIn.",
    url: "/accounts/linkedin/login/",
    buttonText: "Connect ↗",
  },
  {
    type: "link",
    name: "Twitter",
    brandIcon: TwitterIcon,
    description: "Crosspost your newsletters to Twitter.",
    url: "/accounts/twitter_oauth2/login/",
    buttonText: "Connect ↗",
  },
  ...Object.values(INTEGRATION_ID_TO_CONFIGURATION),
];
