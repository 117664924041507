<template>
  <!-- This should probably be hoisted up but it's fine for now -->
  <TooltipProvider :delay-duration="0">
    <TooltipRoot
      :disabled="props.disabled"
      :disable-hoverable-content="!props.interactive"
      :open="isOpen"
      @update:open="open = $event"
    >
      <slot></slot>
    </TooltipRoot>
  </TooltipProvider>
</template>

<script setup lang="ts">
import { TooltipProvider, TooltipRoot } from "radix-vue";
import { ref, toRef } from "vue";

const props = defineProps<{
  disabled?: boolean;
  interactive?: boolean;
  alwaysShow?: boolean;
}>();

// We have to make the open state controlled just to have the option to
// force-show in some scenarios, awful.
const open = ref(false);
const isOpen = toRef(() => !props.disabled && (props.alwaysShow || open.value));
</script>
