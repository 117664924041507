<template>
  <transition
    enter-active-class="duration-200 ease-out"
    enter-from-class="transform opacity-0 translate-y-10"
    enter-to-class="opacity-100"
    leave-active-class="duration-200 ease-in"
    leave-from-class="opacity-100"
    leave-to-class="transform opacity-0 translate-y-10"
  >
    <div
      :class="{
        'bg-gray-200 rounded border border-solid border-gray-300 px-2 py-2 text-sm font-bold text-gray-600 flex items-center justify-between absolute z-50 right-[20px] left-[20px] bottom-[20px] transition space-x-2 drop-shadow-lg @container': true,
      }"
    >
      <slot></slot>
    </div>
  </transition>
</template>

<script lang="ts" setup></script>
