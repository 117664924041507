<template>
  <DropdownMenuRoot v-if="forceFloating || !isMobile" :modal="false">
    <slot></slot>
  </DropdownMenuRoot>
  <DrawerRoot v-else>
    <slot></slot>
  </DrawerRoot>
</template>

<script setup lang="ts">
import { DropdownMenuRoot } from "radix-vue";
import { DrawerRoot } from "vaul-vue";

import { useMediaQuery } from "@/lib/media_query";

import { provide } from "./context";

const isMobile = useMediaQuery("(width < 768px) and (pointer: coarse)");

const props = defineProps<{
  forceFloating?: boolean;
}>();

provide({
  get isMobile() {
    return !props.forceFloating && isMobile.value;
  },
});
</script>
