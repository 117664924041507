<template>
  <tr>
    <td class="font-mono pl-4">{{ props.item[0] }}</td>
    <td class="font-mono pr-4">
      <div v-for="subitem in props.item[1]" :key="subitem" class="text-right">
        {{ subitem }}
      </div>
    </td>
  </tr>
</template>

<script lang="ts" setup>
const props = defineProps<{
  item: [string, string[]];
}>();
</script>
