import { computed, ComputedRef } from "vue";

import OpenAPIValues from "@/autogen/openapi.json";
import { useStore } from "@/store/stores/emails";

const fetchCount = (params: any): ComputedRef<number | undefined> => {
  return computed(() =>
    useStore()
      .fieldToGlobalAggregateCount.status?.filter(
        (aggregate) =>
          params.status.includes(aggregate.parameter) ||
          params.status.length === 0
      )
      .reduce((acc, aggregate) => acc + aggregate.count, 0)
  );
};

export const DEFAULT_PARAMETERS = [
  {
    name: "All",
    parameters: {
      status: OpenAPIValues.components.schemas.EmailStatus.enum,
      email_type: [],
      source: [],
      automation: [],
    },
    fetchCount,
  },
  {
    name: "Drafts",
    parameters: {
      status: ["draft"],
      email_type: [],
      source: [],
      automation: [],
    },
    fetchCount,
  },
  {
    name: "Sent",
    parameters: {
      status: ["sent"],
      email_type: [],
      source: [],
      automation: [],
    },
    fetchCount,
  },
];
