import axios from "axios";

import { components as OpenAPI } from "@/autogen/openapi";
import Urls from "@/autogen/urls";

type Page<T> = {
  results: T[];
  count: number;
};

const SENTINEL_VALUE_TO_INDICATE_NO_SUBSCRIBERS = "NO_SUBSCRIBERS";

export const EMAIL_TYPE_TO_SUBSCRIBER_TYPES: {
  [key in OpenAPI["schemas"]["EmailType"]]:
    | OpenAPI["schemas"]["SubscriberType"][]
    | typeof SENTINEL_VALUE_TO_INDICATE_NO_SUBSCRIBERS;
} = {
  hidden: SENTINEL_VALUE_TO_INDICATE_NO_SUBSCRIBERS,
  archival: SENTINEL_VALUE_TO_INDICATE_NO_SUBSCRIBERS,
  premium: ["churning", "gifted", "trialed", "premium"],
  private: [
    "regular",
    "churning",
    "gifted",
    "trialed",
    "premium",
    "unpaid",
    "premium",
  ],
  churned: ["churned"],
  free: ["regular", "unpaid"],
  public: [
    "regular",
    "churning",
    "gifted",
    "trialed",
    "premium",
    "unpaid",
    "premium",
  ],
};

const padDateRange = (dateRange: string[]): string[] => {
  if (dateRange.length === 1) {
    return [dateRange[0], ""];
  }
  if (dateRange.length === 2 && dateRange[0] === undefined) {
    return ["", dateRange[1]];
  }
  return dateRange;
};

export const listSubscribers = async (
  baseParams: any,
  signal?: AbortSignal
): Promise<Page<OpenAPI["schemas"]["Subscriber"]>> => {
  const params = new URLSearchParams();
  const mungedParams = {
    ...baseParams,
    date: padDateRange(baseParams.date || []),
  };
  if (
    mungedParams.subscriber_type &&
    mungedParams.subscriber_type === SENTINEL_VALUE_TO_INDICATE_NO_SUBSCRIBERS
  ) {
    return {
      results: [],
      count: 0,
    };
  }
  Object.entries(mungedParams).map(([key, value]: any) => {
    (value || []).map((v: string) => {
      params.append(key, v);
    });
  });
  const response = await axios.get<Page<OpenAPI["schemas"]["Subscriber"]>>(
    Urls["api:create_subscriber"](),
    {
      signal,
      params,
      headers: {
        "X-API-Version": "2024-09-30",
      },
    }
  );
  return response.data;
};
