<template>
  <div class="my-2">
    <DataTable :columns="['Key', 'Value']" :items="mungedParameters">
      <template #row="{ item }">
        <ParameterTableRow :item="item" />
      </template>
      <template #empty-state>
        <DataTableEmptyState> No filters selected. </DataTableEmptyState>
      </template>
    </DataTable>
  </div>
</template>

<script lang="ts" setup generic="T extends string">
import { computed } from "vue";

import DataTable from "@/components/DataTable/DataTable.vue";
import DataTableEmptyState from "@/components/DataTable/DataTableEmptyState.vue";
import { Parameter } from "@/components/Layout/ParameterWidget/lib";

import ParameterTableRow from "./ParameterTableRow.vue";

const props = defineProps<{
  parameters: Parameter<T>;
}>();

const mungedParameters = computed(
  () =>
    Object.entries(props.parameters).filter(
      ([, value]) => (value as any).length > 0
    ) as [string, string[]][]
);
</script>
