<template>
  <Tooltip.Root :disabled="!tzDate">
    <Tooltip.Trigger>
      <span v-if="tzDate" class="tabular-nums truncate">
        {{
          relative
            ? formatRelativeTime(tzDate) + ` ago`
            : withTime
            ? formatDateTime(tzDate)
            : formatDate(tzDate)
        }}
      </span>
      <span v-else class="hidden lg:block">—</span>
    </Tooltip.Trigger>

    <Tooltip.Content v-if="tzDate" width-class="max-w-72">
      <p class="text-xs mb-1 uppercase font-bold text-gray-300">
        Time conversion
      </p>

      <div class="grid grid-cols-[1fr,3fr] w-full whitespace-nowrap">
        <div class="text-left">
          {{ formatTimezoneToLocation(newsletterTz || localTz) }}
        </div>
        <div class="text-right font-bold tabular-nums">
          {{ formatDateTime(tzDate.withTimeZone(newsletterTz || localTz)) }}
        </div>

        <template v-if="newsletterTz && localTz !== newsletterTz">
          <div class="text-left">
            {{ formatTimezoneToLocation(localTz) }}
          </div>
          <div class="text-right font-bold tabular-nums">
            {{ formatDateTime(tzDate.withTimeZone(localTz)) }}
          </div>
        </template>

        <template v-if="!(localTz + (newsletterTz || '')).includes('UTC')">
          <div class="text-left">UTC</div>
          <div class="text-right font-bold tabular-nums">
            {{ formatDateTime(tzDate.withTimeZone("Etc/UTC")) }}
          </div>
        </template>
      </div>
    </Tooltip.Content>
  </Tooltip.Root>
</template>

<script lang="ts" setup>
import { TZDate } from "@date-fns/tz";
import { computed } from "vue";

import Tooltip from "@/design_system/Tooltip";
import { formatDate, formatDateTime, formatRelativeTime } from "@/lib/time";
import { useStore as useScaffoldingStore } from "@/store/stores/scaffolding";

import { formatTimezoneToLocation } from "./timezone-conversion";

const props = defineProps<{
  value: string | Date | TZDate | undefined;
  relative?: boolean;
  withTime?: boolean;
}>();

const store = useScaffoldingStore();

const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
const newsletterTz = computed(() => store.newsletter?.timezone);

const tzDate = computed(() => {
  const value = props.value;

  if (!value) return undefined;
  if (value instanceof TZDate) return value;

  // types doesn't like it if it's mixed with the string param variant
  if (value instanceof Date) return new TZDate(value);

  return new TZDate(value);
});
</script>
