import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";

import type { components as OpenAPI } from "@/autogen/openapi";
import Urls from "@/autogen/urls";
import { Selection } from "@/components/Layout/ListView/lib";
import api from "@/lib/api/base";

export type CreateParams = { [key: string]: any } & {
  selection: Selection<{ id: string }>;
  action_type: OpenAPI["schemas"]["BulkActionType"];
};

export const create = async (
  data: CreateParams
): Promise<OpenAPI["schemas"]["BulkAction"]> => {
  const ids =
    data.selection.mode === "all"
      ? ["all"]
      : data.selection.mode === "all_except"
      ? data.selection.items.map((item) => `-${item.id}`)
      : data.selection.items.map((item) => item.id);

  // Remove selection and type information from data.
  const { selection, action_type, ...rest } = data;
  const payload = {
    type: action_type,
    metadata: {
      ids,
      ...rest,
    },
  };

  const response = await api.path("/bulk_actions").method("post").create()(
    payload
  );
  return response.data;
};

export const retrieve = async (
  id: string
): Promise<OpenAPI["schemas"]["BulkAction"]> => {
  const response = await axios.get(Urls["api:retrieve_bulk_action"](id));
  return response.data;
};

export const useStore = defineStore("bulk-actions", () => {
  const bulkActions = ref<
    (OpenAPI["schemas"]["BulkAction"] & { onSuccess?: () => void })[]
  >([]);
  const isCreatingBulkAction = ref(false);

  const draft = ref<CreateParams | null>(null);
  const setDraft = (data: CreateParams | null): void => {
    draft.value = data;
  };

  const createBulkAction = async ({
    data,
    onSuccess,
  }: {
    data: CreateParams;
    onSuccess: () => void;
  }): Promise<void> => {
    isCreatingBulkAction.value = true;
    const bulkAction = await create(data);
    bulkActions.value.push({
      ...bulkAction,
      onSuccess,
    });
    isCreatingBulkAction.value = false;
  };

  const retrieveBulkAction = async (id: string): Promise<void> => {
    const bulkAction = await retrieve(id);
    bulkActions.value = bulkActions.value.map((ba) =>
      ba.id === id
        ? {
            ...ba,
            ...bulkAction,
          }
        : ba
    );
  };

  return {
    draft,
    setDraft,
    bulkActions,
    createBulkAction,
    isCreatingBulkAction,
    retrieveBulkAction,
  };
});
