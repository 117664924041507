// See `icon-mapping-micro.ts` for context, this contains the mini variant of it
// instead for use in <EmptyState>

import type { Component } from "vue";

import ArrowPathIcon from "./heroicons/arrow-path-mini.svg";
import BookOpenIcon from "./heroicons/book-open-mini.svg";
import CalendarIcon from "./heroicons/calendar-mini.svg";
import ChartBarIcon from "./heroicons/chart-bar-mini.svg";
import ChatBubbleLeftRightIcon from "./heroicons/chat-bubble-left-right-mini.svg";
import ClipboardIcon from "./heroicons/clipboard-mini.svg";
import CodeBracketIcon from "./heroicons/code-bracket-mini.svg";
import CreditCardIcon from "./heroicons/credit-card-mini.svg";
import EnvelopeIcon from "./heroicons/envelope-mini.svg";
import GiftIcon from "./heroicons/gift-mini.svg";
import GlobeAltIcon from "./heroicons/globe-alt-mini.svg";
import LockClosedIcon from "./heroicons/lock-closed-mini.svg";
import NoSymbolIcon from "./heroicons/no-symbol-mini.svg";
import QuestionMarkCircleIcon from "./heroicons/question-mark-circle-mini.svg";
import RSSIcon from "./heroicons/rss-mini.svg";
import SparklesIcon from "./heroicons/sparkles-mini.svg";
import TagIcon from "./heroicons/tag-mini.svg";
import UserGroupIcon from "./heroicons/user-group-mini.svg";

export const iconMappingMini = {
  calendar: CalendarIcon,
  clipboard: ClipboardIcon,
  envelope: EnvelopeIcon,
  gift: GiftIcon,
  rss: RSSIcon,
  sparkles: SparklesIcon,
  tag: TagIcon,

  "arrow-path": ArrowPathIcon,
  "book-open": BookOpenIcon,
  "chart-bar": ChartBarIcon,
  "chat-bubble-left-right": ChatBubbleLeftRightIcon,
  "code-bracket": CodeBracketIcon,
  "credit-card": CreditCardIcon,
  "globe-alt": GlobeAltIcon,
  "lock-closed": LockClosedIcon,
  "no-symbol": NoSymbolIcon,
  "question-mark-circle": QuestionMarkCircleIcon,
  "user-group": UserGroupIcon,

  // Makes it spin, see <EmptyState>
  "special-spin": ArrowPathIcon,
} satisfies Record<string, Component>;

export type MappedIconMini = keyof typeof iconMappingMini;
