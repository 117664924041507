<template>
  <div class="@container">
    <ToggleGroupRoot
      v-model="groupValue"
      class="@lg:grid-cols-2 @xl:grid-cols-3 @2xl:grid-cols-4 grid grid-cols-1 gap-2"
    >
      <ToggleGroupItem
        v-for="option in options"
        :key="option.id"
        :value="option.id"
        :disabled="option.disabled"
        :class="[
          `group flex items-center rounded-lg border p-2 outline-blue-600 transition-colors hover:bg-blue-50`,
          !modelValue.includes(option.id)
            ? `border-gray-300 bg-white hover:border-blue-300`
            : `border-blue-300 bg-blue-100`,
        ]"
        :aria-label="option.name"
        :aria-description="option.description"
      >
        <div class="w-5 shrink"></div>
        <div class="flex min-w-0 grow flex-col">
          <p class="truncate text-sm font-medium">
            {{ option.name }}
          </p>
          <p class="break-words text-xs text-gray-500 empty:hidden">
            {{ option.description }}
          </p>
        </div>
        <div class="ml-1 shrink-0">
          <div
            v-if="modelValue.includes(option.id)"
            class="grid h-4 w-4 shrink-0 place-items-center rounded-full bg-blue-600"
          >
            <svg viewBox="0 0 16 16" fill="white">
              <path
                d="M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z"
              />
            </svg>
          </div>
          <div
            v-else
            class="h-4 w-4 shrink-0 rounded-full border border-gray-300 bg-white"
          ></div>
        </div>
      </ToggleGroupItem>
    </ToggleGroupRoot>
  </div>
</template>

<script setup lang="ts">
import { ToggleGroupItem, ToggleGroupRoot } from "radix-vue";
import { computed } from "vue";

type Option = {
  id: string;
  name: string;
  description?: string;
  disabled?: boolean;
};

const modelValue = defineModel<string[]>({ required: true });
const props = defineProps<{
  options: Option[];
  radio?: boolean;
}>();

const groupValue = computed({
  get() {
    if (props.radio) {
      return modelValue.value[0];
    }

    return modelValue.value;
  },
  set(next) {
    if (next === undefined) {
      return;
    }

    if (!Array.isArray(next)) {
      next = [next];
    }

    modelValue.value = next;
  },
});
</script>
