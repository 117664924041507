<template>
  <div
    :class="[
      'border-b border-gray-700',
      !context.isMobile ? '-mx-2 my-2' : '-mx-3 my-4',
    ]"
  ></div>
</template>

<script setup lang="ts">
import { inject } from "./context";

const context = inject();
</script>
