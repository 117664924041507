<template>
  <component
    :is="iconMappingMini[icon]"
    class="size-5 mx-auto mb-4"
    :class="{ 'animate-spin': icon === 'special-spin' }"
  />
  <div class="font-bold text-lg">{{ props.header }}</div>
  <div class="font-medium text-lg max-w-prose mx-auto">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import {
  type MappedIconMini,
  iconMappingMini,
} from "@/icons/icon-mapping-mini";

const props = defineProps<{
  icon: MappedIconMini;
  header: string;
}>();
</script>
