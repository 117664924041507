import { onMounted, onUnmounted, ref } from "vue";

const useInterval = (
  callback: () => void,
  delay: number,
  options?: { immediate?: boolean }
) => {
  const savedInterval = ref<ReturnType<typeof setInterval> | null>(null);

  onMounted(() => {
    savedInterval.value = setInterval(callback, delay);
  });

  onUnmounted(() => {
    if (savedInterval.value) {
      clearInterval(savedInterval.value);
    }
  });

  if (options?.immediate) {
    callback();
  }

  return savedInterval;
};

export default useInterval;
