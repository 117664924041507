<template>
  <Header :breadcrumbs="props.breadcrumbs">
    <template #actions>
      <slot name="actions" />
    </template>
  </Header>
  <slot name="callouts" />
  <PageContent>
    <div
      v-if="pending"
      class="flex absolute top-0 left-0 right-0 bottom-0 items-center z-10"
    >
      <div class="mx-auto text-2xl text-center space-y-2 text-gray-400">
        <ArrowPathIcon class="size-6 mx-auto animate-spin" />
        <div>Loading</div>
      </div>
    </div>
    <div
      v-else-if="$slots.empty"
      class="flex absolute top-0 left-0 right-0 bottom-0 items-center z-10 empty:hidden"
    >
      <slot name="empty" />
    </div>
    <slot name="body" />
  </PageContent>
</template>

<script lang="ts" setup>
import PageContent from "@/components/Layout/PageContent.vue";
import Header, { Props as HeaderProps } from "@/design_system/View/Header.vue";
import ArrowPathIcon from "@/icons/heroicons/arrow-path-outline.svg";

export type Props = HeaderProps & {
  pending?: boolean;
};

const props = defineProps<Props>();
</script>
