<template>
  <Root :disabled="!planRequired">
    <Trigger>
      <slot :plan-required="planRequired" :redirect="redirect"></slot>
    </Trigger>

    <Content v-if="planRequired" side="left">
      Upgrade to {{ planRequired.name }} to unlock this
    </Content>
  </Root>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";

import { Feature } from "@/screens/Billing/lib";
import { useStore as useScaffoldingStore } from "@/store/stores/scaffolding";

import Content from "./Content.vue";
import Root from "./Root.vue";
import Trigger from "./Trigger.vue";

const router = useRouter();
const store = useScaffoldingStore();

const props = defineProps<{
  feature: Feature;
}>();

const planRequired = computed(() => {
  return store.calculatePlanRequirement(props.feature);
});

const redirect = () => {
  router.push({ name: "/settings/billing" });
};
</script>
