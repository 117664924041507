<template>
  <div class="flex flex-col bg-gray-100 pt-3">
    <DateInput :id="id + 'start'" v-model="startDate" label="Start date" />
    <DateInput :id="id + 'end'" v-model="endDate" label="End date" />
  </div>
</template>

<script setup lang="ts">
import { computed, useId } from "vue";

import DateInput from "@/components/Utilities/Forms/DateInput.vue";

const values = defineModel<string[]>();
const id = useId();

const startDate = computed({
  get() {
    return values.value?.[0];
  },
  set(next) {
    const end = values.value?.[1];

    if (next || end) {
      // @ts-expect-error
      values.value = [next, end];
    } else {
      values.value = undefined;
    }
  },
});

const endDate = computed({
  get() {
    return values.value?.[1];
  },
  set(next) {
    const start = values.value?.[0];

    if (next || start) {
      // @ts-expect-error
      values.value = [start, next];
    } else {
      values.value = undefined;
    }
  },
});
</script>
