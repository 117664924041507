import axios from "axios";
import { defineStore } from "pinia";
import { ref } from "vue";

import Urls from "@/autogen/urls";
import { Notification } from "@/types/notification";

const listNotifications = async (): Promise<Notification[]> => {
  const response = await axios.get(Urls["notifications-list"]());
  return response.data.notifications;
};

const createNotificationReceipt = async (
  notification: Notification
): Promise<void> => {
  await axios.post(Urls["notification-receipts-create"](), {
    notification_id: notification.id,
  });
  return;
};

export const useStore = defineStore("notifications", () => {
  const notifications = ref<Notification[]>([]);

  const list = async () => {
    notifications.value = await listNotifications();
  };

  const markAsRead = async (notification: Notification) => {
    notifications.value = notifications.value.filter(
      (n) => n.id !== notification.id
    );
    await createNotificationReceipt(notification);
  };

  return {
    notifications,
    list,
    markAsRead,
  };
});
