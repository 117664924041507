<template>
  <TooltipPortal>
    <TooltipContent
      :side="props.side ?? 'top'"
      :align="props.align"
      :class="[
        `bg-gray-5 text-tooltip z-30 rounded-md p-2.5 text-center text-white shadow-lg`,
        props.widthClass ?? `min-w-32 max-w-48`,
      ]"
      :collision-padding="10"
    >
      <slot></slot>

      <TooltipArrow class="fill-gray-5" :width="8" />
    </TooltipContent>
  </TooltipPortal>
</template>

<script setup lang="ts">
import { TooltipArrow, TooltipContent, TooltipPortal } from "radix-vue";

const props = defineProps<{
  widthClass?: string;
  side?: "top" | "right" | "bottom" | "left";
  align?: "start" | "center" | "end";
}>();
</script>
