<template>
  <ToggleGroupItem
    :value="parameter.name"
    :aria-label="parameter.name"
    :aria-description="count !== undefined ? `${count} items` : undefined"
    class="group flex items-center gap-2 rounded-md px-2 py-2 text-sm font-medium text-gray-600 transition-colors hover:bg-gray-200 hover:text-gray-700 data-[state=on]:bg-gray-600 data-[state=on]:text-white lg:py-1"
  >
    <span>{{ parameter.name }}</span>
    <span
      v-if="count !== undefined"
      class="font-mono text-xs font-bold text-gray-400 group-data-[state=on]:text-gray-300"
    >
      {{ count }}
    </span>
  </ToggleGroupItem>
</template>

<script setup lang="ts">
import { ToggleGroupItem } from "radix-vue";
import { computed } from "vue";

import { DefaultParameter } from "./lib";

const props = defineProps<{
  parameter: DefaultParameter<any>;
}>();

const count = computed(() => {
  return props.parameter.fetchCount(props.parameter.parameters).value;
});
</script>
