<template>
  <component :is="!context.isMobile ? DropdownMenuItem : DrawerClose" as-child>
    <a
      :href="props.href"
      target="_blank"
      rel="noopener noreferrer nofollow"
      :class="[
        'group/item',
        'relative flex shrink-0 cursor-pointer select-none items-center gap-3 self-stretch rounded-md border-none px-3 text-left text-sm text-current transition-colors duration-75 hover:bg-gray-700',
        'focus-visible:outline outline-2 outline-gray-100',
        !context.isMobile && 'h-7',
        context.isMobile && 'h-9',
      ]"
    >
      <slot v-if="slots.icon" name="icon" />
      <component
        :is="iconMappingMicro[props.icon]"
        v-else-if="props.icon"
        class="size-4 shrink-0 text-gray-400 transition-colors"
      />

      <span class="min-w-0 grow break-words">
        {{ props.label }}
      </span>

      <ArrowUpRightIcon class="size-4 shrink-0 text-gray-400" />
    </a>
  </component>
</template>

<script setup lang="ts">
import { DropdownMenuItem } from "radix-vue";
import { DrawerClose } from "vaul-vue";

import ArrowUpRightIcon from "@/icons/heroicons/arrow-up-right-micro.svg";
import { iconMappingMicro, MappedIconMicro } from "@/icons/icon-mapping-micro";

import { inject } from "./context";

const context = inject();

const props = defineProps<{
  label: string;
  icon?: MappedIconMicro;
  href?: string;
}>();

const slots = defineSlots<{
  icon?(): unknown;
}>();
</script>
