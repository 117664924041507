<template>
  <TransitionRoot show as="template" appear>
    <Dialog as="div" class="relative z-10" @close="$emit('close')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity"
        />
      </TransitionChild>

      <div
        class="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20 flex items-start"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 scale-95"
          enter-to="opacity-100 scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 scale-100"
          leave-to="opacity-0 scale-95"
        >
          <DialogPanel
            class="mx-auto w-[600px] max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
          >
            <ComboboxRoot
              v-model:search-term="modelValue"
              class="contents"
              @update:model-value="act"
            >
              <div class="relative flex items-center">
                <div class="ml-3.5 w-6 grid place-items-center text-gray-600">
                  <ArrowPathIcon v-if="pending" class="h-4 w-4 animate-spin" />
                  <MagnifyingGlassIcon v-else class="h-4 w-4" />
                </div>

                <ComboboxInput
                  class="h-12 grow border-0 px-3 text-sm placeholder:text-gray-400 focus:ring-0"
                  placeholder="Search by email, name, content..."
                />
              </div>

              <ComboboxContent
                v-if="data.length > 0"
                class="border-t border-gray-200"
              >
                <div class="max-h-96 overflow-y-auto px-4 py-3">
                  <SearchItem
                    v-for="item in data.slice(0, 10)"
                    :key="item.obj.id"
                    :item="item"
                  />

                  <ComboboxItem
                    :key="modelValue"
                    :value="createViewAllAction(modelValue)"
                    class="-mx-2 mt-2 flex select-none gap-3 rounded-md px-1.5 py-1 text-gray-700 data-[highlighted]:bg-gray-100 data-[highlighted]:text-gray-900"
                  >
                    <div
                      class="flex shrink-0 items-center justify-center self-start rounded bg-gray-200 p-1"
                    >
                      <EllipsisIcon class="h-4 w-4" />
                    </div>
                    <span class="my-0.5 grow text-sm font-medium">
                      View all results &rarr;
                    </span>
                  </ComboboxItem>
                </div>
              </ComboboxContent>
              <ComboboxContent
                v-else-if="!pending && value !== '' && data.length === 0"
                class="py-14 px-6 text-center text-sm"
              >
                <ExclamationTriangleIcon
                  icon="exclamation-triangle"
                  class="mx-auto size-6 text-gray-500"
                />
                <p class="mt-4 font-semibold text-gray-900">
                  No results found for {{ modelValue }}
                </p>
                <p class="mt-2 text-gray-500">
                  No components found for this search term. Please try again.
                </p>
              </ComboboxContent>
            </ComboboxRoot>
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script lang="ts" setup>
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import {
  ComboboxContent,
  ComboboxInput,
  ComboboxItem,
  ComboboxRoot,
} from "radix-vue";
import { useRouter } from "vue-router/auto";

import ArrowPathIcon from "@/icons/heroicons/arrow-path-micro.svg";
import EllipsisIcon from "@/icons/heroicons/ellipsis-horizontal-micro.svg";
import ExclamationTriangleIcon from "@/icons/heroicons/exclamation-triangle-solid.svg";
import MagnifyingGlassIcon from "@/icons/heroicons/magnifying-glass-micro.svg";
import { useKeybinds } from "@/lib/hotkeys";
import { type Action, createViewAllAction } from "@/lib/search";
import { useStore } from "@/store/stores/scaffolding";
import { SearchResult } from "@/types/search_result";

import SearchItem from "./SearchItem.vue";

defineProps<{
  pending: boolean;
  data: SearchResult[];
  value: string;
}>();

const emit = defineEmits(["close", "update:modelValue"]);

const modelValue = defineModel<string>({
  required: true,
});

const router = useRouter();
const store = useStore();

const act = (_action: unknown) => {
  const action = _action as Action;

  if (action.type === "route") {
    router.push(action.route);
  } else if (action.type === "action") {
    if (action.action === "switch_newsletter") {
      const newsletter = store.account?.permissions.find(
        (n) => n.newsletter.id === action.payload.newsletter
      )?.newsletter;
      if (newsletter) {
        store.switchNewsletter(newsletter);
      }
    }
  }

  emit("close");
};

useKeybinds({
  Escape(ev) {
    if (ev.defaultPrevented) {
      return;
    }

    emit("close");
    return true;
  },
});
</script>
