<template>
  <Dialog variant="narrow">
    <template #header></template>
    <template #body>
      <div class="text-center space-y-4">
        <ExclamationTriangleIcon class="size-9 text-gray-500 mb-2 mx-auto" />
        <div class="text-xl font-bold">Error</div>
        <div class="text-lg">
          {{
            props.error.detail ||
            "Buttondown ran into an error. (Sorry, that's not helpful, but our team has been alerted.)"
          }}
        </div>
        <ActionButton
          v-if="shouldShowUpgradeButton"
          text="Upgrade"
          :to="{
            name: '/settings/billing/pricing',
          }"
          variant="primary"
        />
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts" setup>
import { computed } from "vue";

import type { components as OpenAPI } from "@/autogen/openapi";
import ActionButton from "@/components/Utilities/ActionButton.vue";
import Dialog from "@/design_system/Dialog/Component.vue";
import ExclamationTriangleIcon from "@/icons/heroicons/exclamation-triangle-solid.svg";

const props = defineProps<{
  error: OpenAPI["schemas"]["ErrorMessage"];
}>();

const shouldShowUpgradeButton = computed(() => {
  return props.error.detail?.includes("please upgrade your account");
});
</script>
