<template>
  <div class="p-4 text-sm space-y-4 sticky top-0">
    <div v-for="section in COMPILED_ROUTES" :key="section.name">
      <div class="font-bold -mx-2 mb-1 text-gray-500 text-xs uppercase">
        {{ section.name }}
      </div>
      <div
        v-for="child in section.children.filter(
          (child) =>
            child.hide === undefined ||
            (newsletter !== null && !child.hide(currentPermissions, newsletter))
        )"
        :key="child.title"
        class="-mx-4"
      >
        <NavigationItem
          v-if="child.route && child.title && child.icon"
          :title="child.title"
          :route="child.route"
          :icon="child.icon"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { type Component, computed } from "vue";

import { RouteLocation } from "@/router/types";
import NavigationItem from "@/scaffolding/Sidebar/NavigationItem.vue";
import { SEARCH_CONTEXT } from "@/search/routes";
import { useStore } from "@/store/stores/scaffolding";
import { Permission } from "@/types/account";
import { Newsletter } from "@/types/newsletter";

const scaffoldingStore = useStore();
const currentPermissions = computed(() => scaffoldingStore.currentPermissions);
const newsletter = computed(() => scaffoldingStore.newsletter);

const COMPILED_ROUTES: {
  name: string;
  children: {
    route: RouteLocation;
    title?: string;
    icon?: Component;
    hide?: (
      permissions: Permission["permissions"],
      newsletter: Newsletter
    ) => boolean;
  }[];
}[] = [
  {
    name: "Newsletter",
    children: [
      SEARCH_CONTEXT["/settings/basics"],
      SEARCH_CONTEXT["/settings/design"],
      SEARCH_CONTEXT["/settings/embedding"],
      SEARCH_CONTEXT["/settings/subscribing"],
      SEARCH_CONTEXT["/settings/integrations"],
      SEARCH_CONTEXT["/settings/team"],
    ],
  },
  {
    name: "Account",
    children: [
      SEARCH_CONTEXT["/settings/profile"],
      SEARCH_CONTEXT["/settings/notifications"],
      SEARCH_CONTEXT["/settings/security"],
      SEARCH_CONTEXT["/settings/referrals"],
      SEARCH_CONTEXT["/settings/billing"],
      SEARCH_CONTEXT["/settings/subscriptions"],
      ...(!scaffoldingStore.isDemo
        ? [SEARCH_CONTEXT["/settings/danger-zone"]]
        : []),
    ],
  },
];
</script>
