// Derived from https://github.com/mary-ext/pkg-keybinds

// Use this (awful) web page to visualize the keybinds
// https://mary.my.id/tools/keydown-visualizer
//
// Meta/Control should be mapped to $mod for a cross-platform keybind.
// Worth noting that keybinds are *case-sensitive*.

import { onMounted, onUnmounted, onWatcherCleanup, watch } from "vue";

const PLATFORM = typeof navigator == "object" ? navigator.platform : "";
const IS_APPLE = /iP[aho]|Mac/.test(PLATFORM);
const IS_WINDOWS = PLATFORM == "Win32";

// Alias for `$mod` modifier
const MOD = IS_APPLE ? "Meta" : "Control";

// Array of "known" key modifiers
const MODIFIERS = ["Alt", "Control", "Meta", "Shift"];

export type KeybindListener = (ev: KeyboardEvent) => boolean | void;
export type KeybindMapping = Record<string, KeybindListener>;

type Keybind = [mods: string[], key: string, flags: number];

// Bitflags to inhibit certain keybinds from conflicting with user expectations
const INHIBIT_TEXT = 1 << 0;
const INHIBIT_SELECT = 1 << 1;

const INHIBIT_ARROW = 1 << 2;
const INHIBIT_ENTER = 1 << 3;
const INHIBIT_ENTER_EXTRA = 1 << 4;
const INHIBIT_SPACE = 1 << 5;

// Flags to check for when dealing with a certain <input> type
const types: Record<string, number | undefined> = {
  email: INHIBIT_TEXT,
  number: INHIBIT_TEXT,
  password: INHIBIT_TEXT,
  search: INHIBIT_TEXT,
  tel: INHIBIT_TEXT,
  text: INHIBIT_TEXT,
  url: INHIBIT_TEXT,

  date: INHIBIT_TEXT | INHIBIT_ENTER,
  datetime: INHIBIT_TEXT | INHIBIT_ENTER,
  month: INHIBIT_TEXT | INHIBIT_ENTER,
  time: INHIBIT_TEXT | INHIBIT_ENTER,
  week: INHIBIT_TEXT | INHIBIT_ENTER,

  button: INHIBIT_ENTER | INHIBIT_SPACE,
  color: INHIBIT_ENTER | INHIBIT_SPACE,
  file: INHIBIT_ENTER | INHIBIT_SPACE,
  image: INHIBIT_ENTER | INHIBIT_SPACE,
  reset: INHIBIT_ENTER | INHIBIT_SPACE,
  submit: INHIBIT_ENTER | INHIBIT_SPACE,

  checkbox: INHIBIT_SPACE,

  radio: INHIBIT_ARROW | INHIBIT_SPACE,
  range: INHIBIT_ARROW,
};

const parseKeybind = (keybind: string): Keybind => {
  const mods = keybind
    .replace(/\s/g, "")
    .split(/\b\+/)
    .map((mod) => (mod == "$mod" ? MOD : mod));

  const key = mods.pop()!;

  const len = mods.length;
  const standalone = len == 0;
  const shiftModifierOnly = len == 1 && mods[0] == "Shift";
  const modModifierOnly = len == 1 && mods[0] == MOD;
  const modShiftModifierOnly =
    len == 2 && mods.includes(MOD) && mods.includes("Shift");

  let flags = 0;

  // We will be checking for certain keybinds to forbid in certain contexts:
  //
  // - <input type=text>, <textarea> and <div contenteditable>
  //   - Text insertion (a, s, ...)
  //   - Uppercase text insertion (Shift+A, Shift+S, ...)
  //   - Text deletion (Backspace, $mod+Backspace)
  //   - Cursor per-char positioning (ArrowLeft, ArrowRight, ...)
  //   - Cursor per-char selection (Shift+ArrowLeft, Shift+ArrowRight, ...)
  //   - Cursor per-word positioning ($mod+ArrowLeft, $mod+ArrowRight, ...)
  //   - Cursor per-word selection ($mod+Shift+ArrowLeft, $mod+Shift+ArrowRight, ...)
  //   - Common text manipulation shortcuts ($mod+a, $mod+v, ...)
  //   - Multiline only: New line insertion (Enter)
  //   - Date/time inputs only: Open picker UI (Enter)
  //
  // - <input type=checkbox>
  //   - Tick/unticking check (Space)
  //
  // - <input type=radio>
  //   - Radio selection (ArrowUp, ArrowDown, ...)
  //   - Tick radio (Space)
  //
  // - <input type=range>
  //   - Selection (ArrowLeft, ArrowRight, ...)
  //
  // - <button> and <input type=file>
  //   - Action trigger (Space, Enter)
  //
  // - <a href>
  //   - Link navigation (Enter, $mod+Enter, ...)
  //
  // - <select>
  //   - Jump to value (a, s, ...)
  //   - Choice selection (ArrowUp, ArrowDown, ...)

  // Awful tricks to reduce footprint... I consider this to be fine because
  // we're not doing this in the keybind handler.
  if (
    // Alt | Control | Escape | Meta | Tab | Enter | ScrollLock
    (standalone && !/Al|Co|Es|F\d|Me|Ta|En|Sc/.test(key)) ||
    // Alt | Control | Escape | Meta | Tab | Enter | Backspace | *Lock | Delete
    (shiftModifierOnly && !/Al|Co|Es|F\d|Me|Ta|En|Ba|Lo|De/.test(key)) ||
    // Arrow* | Back
    (modModifierOnly && /^[acvxyz]|Ar|Ba/.test(key)) ||
    // Arrow
    (modShiftModifierOnly && /V$|Ar/.test(key))
  ) {
    flags |= INHIBIT_TEXT;
  }

  // Alt | Control | Escape | Meta | Tab | Enter | Backspace | *Lock | Delete
  if (standalone && !/Al|Co|Es|F\d|Me|Ta|En|Ba|Lo|De/.test(key)) {
    flags |= INHIBIT_SELECT;
  }

  if (standalone && /Ar/.test(key)) {
    flags |= INHIBIT_ARROW;
  }

  if (standalone && /Sp/.test(key)) {
    flags |= INHIBIT_SPACE;
  }

  if (standalone && /En/.test(key)) {
    flags |= INHIBIT_ENTER;
  }

  if (
    (shiftModifierOnly || modModifierOnly || modShiftModifierOnly) &&
    /En/.test(key)
  ) {
    flags |= INHIBIT_ENTER_EXTRA;
  }

  return [mods, key, flags];
};

/**
 * Check if keybind is allowed to match with specified keyboard event based on
 * its parsed state.
 * @param ev Keyboard event to check
 * @param flags Parsed flags
 * @returns Whether keybind can proceed to be matched
 */
const isKeybindAllowed = (ev: KeyboardEvent, flags: number): boolean => {
  const target = ev.target;

  if (target instanceof HTMLInputElement) {
    const type = target.type;
    return !((types[type] || 0) & flags);
  }

  if (target instanceof HTMLTextAreaElement) {
    const f = INHIBIT_ENTER | INHIBIT_TEXT;
    return !(flags & f);
  }

  if (target instanceof HTMLSelectElement) {
    const f = INHIBIT_SELECT;
    return !(flags & f);
  }

  if (target instanceof HTMLButtonElement) {
    const f = INHIBIT_ENTER | INHIBIT_SPACE;
    return !(flags & f);
  }

  if (target instanceof HTMLAnchorElement) {
    const f = INHIBIT_ENTER | INHIBIT_ENTER_EXTRA;
    return !(flags & f) && target.href != "";
  }

  if (target instanceof HTMLElement && target.isContentEditable) {
    const f = INHIBIT_ENTER | INHIBIT_TEXT;
    return !(flags & f);
  }

  return true;
};

/**
 * Check if a specific modifier is pressed, with special handling for AltGraph
 * modifier.
 * @param ev Keyboard event to check
 * @param mod Modifier to check
 * @returns Whether the modifier is pressed
 */
const matchModifier = (ev: KeyboardEvent, mod: string): boolean => {
  if (ev.getModifierState(mod)) {
    return true;
  }

  // Alias check for AltGr key
  // https://github.com/jamiebuilds/tinykeys/issues/185
  if ((IS_WINDOWS || IS_APPLE) && ev.getModifierState("AltGraph")) {
    return mod === "Alt" || (IS_WINDOWS && mod === "Control");
  }

  return false;
};

/**
 * Check if specified keybind can match with the specified keyboard event
 * @param ev Keyboard event to test
 * @param keybind Keybind to test
 * @returns Whether the keybind matches
 */
const matchKeybind = (
  ev: KeyboardEvent,
  [mods, key, flags]: Keybind
): boolean => {
  return (
    // Check if we can do matching with target element
    isKeybindAllowed(ev, flags) &&
    // Check if key matches either `event.key` or `event.code`
    (ev.key == key || ev.code == key) &&
    // Check if all modifiers passes.
    mods.every((mod) => matchModifier(ev, mod)) &&
    // Don't match on extraneous modifiers
    MODIFIERS.every((mod) => mods.includes(mod) || !matchModifier(ev, mod))
  );
};

// Register a single keydown handler for the entire app, we want to ensure that
// our keybind handlers are always ran from newest to oldest.
const registeredBinds: [Keybind, KeybindListener][][] = [];

if (PLATFORM) {
  window.addEventListener("keydown", (ev: KeyboardEvent) => {
    // loop go fast, don't want this listener to take up much time especially
    // when the user is interacting with the editor.

    for (let i = registeredBinds.length - 1; i >= 0; i--) {
      const group = registeredBinds[i];

      for (let j = 0, jlen = group.length; j < jlen; j++) {
        const [keybind, callback] = group[j];

        if (matchKeybind(ev, keybind)) {
          if (callback(ev)) {
            ev.preventDefault();
            return;
          }
        }
      }
    }
  });
}

export const registerKeybinds = (mapping: KeybindMapping) => {
  const bindings = Object.entries(mapping).map(
    ([raw, listener]): [Keybind, KeybindListener] => {
      return [parseKeybind(raw), listener];
    }
  );

  if (bindings.length === 0) {
    return () => {};
  }

  registeredBinds.push(bindings);
  return () => registeredBinds.splice(registeredBinds.indexOf(bindings), 1);
};

export const useKeybinds = (
  mapping: KeybindMapping | (() => KeybindMapping | undefined)
) => {
  onMounted(() => {
    if (typeof mapping === "function") {
      watch(
        mapping,
        (mapping) => {
          if (!mapping) {
            return;
          }

          onWatcherCleanup(registerKeybinds(mapping));
        },
        { immediate: true }
      );
    } else {
      onUnmounted(registerKeybinds(mapping));
    }
  });
};

const MODIFIER_FORMATTED: Record<string, string> = {
  Control: IS_APPLE ? `^` : `Ctrl`,
  Alt: IS_APPLE ? `⌥` : `Alt`,
  Meta: IS_APPLE ? `⌘` : `Meta`,
};

const KEY_FORMATTED: Record<string, string> = {
  Enter: `↩︎`,
};

export const formatKeybind = (keybind: string) => {
  let [mods, key] = parseKeybind(keybind);

  if (mods.length === 1 && mods.includes("Shift")) {
    if (/^[?/]$/i.test(key)) {
      return key.toUpperCase();
    }
  }

  key = key in KEY_FORMATTED ? KEY_FORMATTED[key] : key.toUpperCase();

  mods = mods.map((mod) => {
    return mod in MODIFIER_FORMATTED ? MODIFIER_FORMATTED[mod] : mod;
  });

  return (mods.length !== 0 ? mods.join(`+`) + `+` : ``) + key;
};
