import type { Email } from "@/types/email";

export const RAW_ORDERS = [
  "modified",
  "published",
  "created",
  "subject",
  "status",
  "audience",
  "clicks",
  "opens",
  "click_rate",
  "open_rate",
  "page_views__lifetime",
  "page_views__30",
  "page_views__7",
  "webmentions",
  "subscriptions",
  "paid_subscriptions",
  "unsubscriptions",
  "replies",
];

export const ORDERS = [
  ...RAW_ORDERS,
  ...RAW_ORDERS.map((order) => `-${order}`),
] as const;

type OrderTuple = typeof RAW_ORDERS;
export type Order = OrderTuple[number];

export const ORDER_TO_SERVER_SIDE_ORDER: {
  [key in Order]: keyof Email;
} = {
  created: "creation_date",
  published: "publish_date",
  audience: "email_type",
  modified: "modification_date",
  subject: "subject",
};

const ORDER_TO_CLIENTSIDE_LAMBDA: {
  [key in Order]: (email: Email) => string | number | undefined;
} = {
  created: (email: Email) => email.creation_date,
  published: (email: Email) => email.publish_date,
  audience: (email: Email) => email.email_type,
  modified: (email: Email) => email.modification_date,
  subject: (email: Email) => email.subject,
  status: (email: Email) => email.status,
  webmentions: (email: Email) => email.analytics?.webmentions,
  subscriptions: (email: Email) => email.analytics?.subscriptions,
  paid_subscriptions: (email: Email) => email.analytics?.paid_subscriptions,
  replies: (email: Email) => email.analytics?.replies,
  page_views__lifetime: (email: Email) => email.analytics?.page_views__lifetime,
  page_views__30: (email: Email) => email.analytics?.page_views__30,
  page_views__7: (email: Email) => email.analytics?.page_views__7,
  clicks: (email: Email) => email.analytics?.clicks,
  opens: (email: Email) => email.analytics?.opens,
  unsubscriptions: (email: Email) => email.analytics?.unsubscriptions,
  click_rate: (email: Email) =>
    (email.analytics?.clicks as number) /
    (email.analytics?.deliveries as number),
  open_rate: (email: Email) =>
    (email.analytics?.opens as number) /
    (email.analytics?.deliveries as number),
};

export const sortEmails = (emails: Email[], order: Order) => {
  return emails.sort((a, b) => {
    // If the order is in the clientside lambda mapping, use that.
    // Otherwise, use the server-side order mapping.
    const aValue = ORDER_TO_CLIENTSIDE_LAMBDA[order]?.(a),
      bValue = ORDER_TO_CLIENTSIDE_LAMBDA[order]?.(b);

    // Sort normally, but check for undefined values first.
    if (aValue === undefined && bValue === undefined) {
      return 0;
    }
    if (aValue === undefined) {
      return -1;
    }
    if (bValue === undefined) {
      return 1;
    }
    if (aValue < bValue) {
      return -1;
    }
    if (aValue > bValue) {
      return 1;
    }
    return 0;
  });
};
