<template>
  <label
    :for="idBecauseTypeScriptCantUnderstandInject"
    class="mb-0 text-gray-900 text-[14px] font-medium"
  >
    <div v-if="beta" class="flex items-center space-x-1">
      <div><slot></slot></div>
      <div
        class="inline-block rounded-full text-xs uppercase bg-green-500 text-white font-bold px-2 py-1"
      >
        Beta
      </div>
    </div>
    <div v-else>
      <slot></slot>
    </div>
  </label>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  inject: ["id"],
  props: {
    beta: Boolean,
  },
  computed: {
    idBecauseTypeScriptCantUnderstandInject(): string {
      // @ts-ignore
      return this.id;
    },
  },
});
</script>
