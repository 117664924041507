import { components as OpenAPI } from "@/autogen/openapi";
import { listEmails } from "@/lib/api/emails";

type Params = {
  ids: string[];
};

export const list = async (
  ids: Params["ids"]
): Promise<OpenAPI["schemas"]["Email"][]> => {
  const response = await listEmails({ ids });
  return response.results;
};
