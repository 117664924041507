/* eslint-disable vue/one-component-per-file */

import type { DefineComponent, Slot } from "vue";
import { defineComponent, onUnmounted, shallowRef } from "vue";

type ObjectLiteralWithPotentialObjectLiterals = Record<
  string,
  Record<string, any> | undefined
>;

type GenerateSlotsFromSlotMap<
  T extends ObjectLiteralWithPotentialObjectLiterals
> = {
  [K in keyof T]: Slot<T[K]>;
};

export type DefineTemplateComponent<
  Bindings extends Record<string, any>,
  MapSlotNameToSlotProps extends ObjectLiteralWithPotentialObjectLiterals
> = DefineComponent & {
  new (): {
    $slots: {
      default: (
        _: Bindings & {
          $slots: GenerateSlotsFromSlotMap<MapSlotNameToSlotProps>;
        }
      ) => any;
    };
  };
};

export type ReuseTemplateComponent<
  Bindings extends Record<string, any>,
  MapSlotNameToSlotProps extends ObjectLiteralWithPotentialObjectLiterals
> = DefineComponent<Bindings> & {
  new (): { $slots: GenerateSlotsFromSlotMap<MapSlotNameToSlotProps> };
};

export type ReusableTemplateReturn<
  Bindings extends Record<string, any>,
  MapSlotNameToSlotProps extends ObjectLiteralWithPotentialObjectLiterals
> = {
  Define: DefineTemplateComponent<Bindings, MapSlotNameToSlotProps>;
  Use: ReuseTemplateComponent<Bindings, MapSlotNameToSlotProps>;
};

export interface CreateReusableTemplateOptions {
  /**
   * Inherit attrs from reuse component.
   *
   * @default true
   */
  inheritAttrs?: boolean;
}

// Unique value that only we can set
const UNINITIALIZED = Symbol();

/**
 * This function creates `define` and `reuse` components in pair,
 * It also allow to pass a generic to bind with type.
 *
 * @see https://vueuse.org/createReusableTemplate
 */
export const createReusableTemplate = <
  Bindings extends Record<string, any>,
  MapSlotNameToSlotProps extends ObjectLiteralWithPotentialObjectLiterals = Record<
    "default",
    undefined
  >
>(
  options: CreateReusableTemplateOptions = {}
): ReusableTemplateReturn<Bindings, MapSlotNameToSlotProps> => {
  const { inheritAttrs = true } = options;

  const held = shallowRef<Slot | typeof UNINITIALIZED>();

  const Define = defineComponent({
    setup(_, { slots }) {
      onUnmounted(() => {
        held.value = UNINITIALIZED;
      });

      return () => {
        held.value = slots.default;
      };
    },
  }) as unknown as DefineTemplateComponent<Bindings, MapSlotNameToSlotProps>;

  const Use = defineComponent({
    inheritAttrs,
    setup(_, { attrs, slots }) {
      return () => {
        const slot = held.value;
        if (slot === UNINITIALIZED) {
          throw new Error(`Template definition not registered yet`);
        }

        const vnode = slot?.({
          ...keysToCamelKebabCase(attrs),
          $slots: slots,
        });

        return inheritAttrs && vnode?.length === 1 ? vnode[0] : vnode;
      };
    },
  }) as unknown as ReuseTemplateComponent<Bindings, MapSlotNameToSlotProps>;

  return { Define, Use };
};

const keysToCamelKebabCase = (obj: Record<string, any>) => {
  const newObj: typeof obj = {};
  for (const key in obj) newObj[camelize(key)] = obj[key];
  return newObj;
};

const camelizeRE = /-(\w)/g;
const camelize = (str: string) => {
  return str.replace(camelizeRE, (_, c) => (c ? c.toUpperCase() : ""));
};
